.ClubsList {
    margin-top: 40px;
}
.ClubDetails {
    margin-top: 80px;
}
.clubs-wrapper {
    max-width: 1300px;
    margin: 40px auto;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}
.ClubsList .ant-card {
    display: flex;
    flex-direction: column;
}
.clubCard .ant-card-cover {
    margin: 0;
}
.clubCard .img-wrapper {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.clubCard .img-wrapper img {
    max-height: 70%;
    max-width: 70%;
    border-radius: 0;
}
.ClubsList .section-title {
    white-space: normal;
    cursor: pointer;
}
.ClubsList .section-title:hover {
    color: #216575;
}
.ClubsList .ant-card-actions {
    margin-top: auto;
}
.club-description {
    line-height: 1.5;
    color: #515358;
    margin-right: 20px;
    margin-top: 20px;
}
.club-logo {
    height: 100px;
    margin-bottom: 20px;
}
.details-box {
    background: #f0f0f0;
}
.details-box-item {
    padding: 20px;
}
.details-box-item .box-text {
    color: #515358;
    margin: 5px 0;
}
.details-box-item a.box-text {
    display: inline-block;
}
.disable-edit {
    pointer-events: none;
    cursor: not-allowed;
    color: #21657526 !important;
}
.allow-edit {
    color: #216575 !important;
}
.prizes {
    display: flex;
    padding: 15px;
}
.prize-wrapper {
    position: relative;
    margin-right: -10px;
}
.prize-year {
    position: absolute;
    top: 28px;
    left: 38px;
    font-weight: bold;
    font-size: 15px;
}
.prizes-small {
    position: absolute;
    z-index: 1;
    display: flex;
    transform: scale(.7);
    transform-origin: 50px -90px;
}

@media screen and (max-width: 900px) {
    .details-box {
        margin: 20px 0;
    }
}