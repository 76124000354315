@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');
@import './Home.css';
@import './Header.css';
@import './Login.css';
@import './Registration.css';
@import './ClubsList.css';
@import './Footer.css';
@import './Contest.css';
@import 'antdOverrides.css';

.App {
  font-family: 'Montserrat', 'Lato';
}

body {
  position: relative;
  margin: 0;
  min-height: 100vh;
}
body::after {
  content: '';
  display: block;
  height: 144px; /* Set same as footer's height */
}

.page-container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.page-wrapper {
  display: flex;
}
.page-col {
  width: 50%;
}
.page-title {
  font-size: 54px;
  font-weight: 300;
}
.page-title:before {
  width: 10px;
  height: 10px;
  background-color: #216575;
  content: '';
  display: inline-block;
  margin-right: 10px;
}
.page-subtitle{
  font-size: 32px;
  margin-bottom: 40px;
}
.page-subtitle:before {
  width: 7px;
  height: 7px;
  background-color: #216575;
  content: '';
  display: inline-block;
  margin-right: 10px;
}
.section-title {
  font-family: 'Montserrat', 'Lato';
  border-left: 2px solid #216575;
  padding-left: 10px;
  font-weight: bold;
  font-size: 16px;
  white-space: normal;
}
.loading {
  padding-top: 350px;
  text-align: center;
}

@media screen and (max-width: 1400px) {
  .page-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 900px) {
  .page-wrapper {
    flex-wrap: wrap;
  }
  .page-col {
    width: 100%;
  }
}