.Navigation .ant-menu-title-content, 
.ant-menu,
.ant-col,
.ant-checkbox-wrapper,
.ant-btn,
.ant-form-item-explain-error,
.ant-card-head-title,
.ant-card,
.ant-divider,
.ant-table-cell,
.ant-select-selection-item,
.ant-space-item,
.ant-input,
.ant-input-number,
.ant-select-dropdown,
.ant-modal,
.ant-upload-text,
.ant-tabs,
input {
    font-family: 'Montserrat', 'Lato';
}
.ant-divider-inner-text{
    font-weight: 600;
}
a,
a:hover{
    text-decoration: none;
}
a,
a:hover,
.ant-btn-link,
.ant-btn-link:not(:disabled):hover,
.logo-uploader {
    color: #820263;
}
.ant-card {
    background: #f0f0f0;
}
.ant-btn.ant-btn-primary {
    font-weight: bold;
}

.ant-row {
    align-items: center;
}
.ant-form-item .ant-form-item-label {
    white-space: normal;
    line-height: 1.2;
}
.ant-form-item .ant-form-item-label > label {
    height: min-content;
    width: min-content;
}
.ant-form-item-label > label::after {
    content: ' ' !important;
}
.ant-form-item-label > label::before {
    align-self: start;
}
