.Footer {
    background-color: #216575;
    color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 144px;
}
.footer-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}