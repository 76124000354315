.Registration{
    margin-top: 40px;
}
.formRow {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.formCol {
    width: 50%;
}
.formRow .ant-form-item,
.formCol {
   flex-grow: 1;
}
.Registration .ant-form-item-label {
    width: 110px;
}
.Registration .ant-space {
    width: 100%;
}
.Registration .formFooter {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}
.uploadContainer {
    display: flex;
    gap: 20px;
}
.Registration .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 8px;
    font-weight: 500;
}
.Registration .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 2px;
}
.Registration .ant-empty-normal {
    margin: 4px;
}
.Registration .ant-empty-image {
    display: none;
}
.Registration .editable-cell {
    position: relative;
}
.Registration .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}
.Registration .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.Registration .ant-table-tbody > tr.ant-table-row > td,
.Registration .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fafafa;
}
.Registration .editable-row .ant-select-selector:hover,
.Registration .editable-row .ant-input:hover,
.Registration .editable-row .ant-input-number:hover,
.Registration .editable-row .ant-picker:hover {
    border: 1px solid #d9d9d9 !important;
}
.Registration .editable-row .ant-select-focused .ant-select-selector,
.Registration .editable-row .ant-input:focus,
.Registration .editable-row .ant-input-number-focused,
.Registration .editable-row .ant-picker-focused {
    background: white !important;
}
.Registration .editable-row .ant-select-selector,
.Registration .editable-row .ant-input,
.Registration .editable-row .ant-input-number,
.Registration .editable-row .ant-picker {
    border:none;
    transition: none;
    background: #fafafa;
}
.Registration .ant-form-item-explain-error {
    scroll-margin-top: 100px;
}
.ant-select-dropdown {
    width: fit-content !important;
}
.rc-virtual-list-holder-inner{
    position: relative!important;
}
.row-important .ant-input,
.row-important .ant-picker .ant-picker-input > input,
.row-important {
    color: #820263;
}
.logo-uploader {
    margin-top: 10px;
}
.dataCard .ant-card-head-title {
    align-self: self-start;
    margin-top: 15px;
}
.members-count {
    float: right;
}
.formFooterCheck {
    max-width: 850px;
    margin-inline-start: 0!important;
    display: flex;
}
.required-sign {
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
}
.Registration .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container {
    transition: none;
}
.Registration .ant-upload-list-item-name {
    pointer-events: none;
}
@media screen and (max-width: 800px) {
    .formRow {
     flex-wrap: wrap;
    }
    .ant-table-wrapper {
        overflow: auto;
    }
}
@media screen and (max-width: 575px) {
    .members-count {
        width: 100px;
    }
}
@media screen and (max-width: 430px) {
    .ant-divider-horizontal.ant-divider-with-text {
        white-space: normal;
    }
}